<template>
    <div>
        <form v-if="state === 'default' || state === 'error'" @submit.prevent="onSubmit">
            <slot />

            <slot name="sendButton" :submit="onSubmit">
                <button type="submit" :class="[buttonClass]" :loading="loading">{{ strings.submit }}</button>
            </slot>
        </form>
        <template v-if="state === 'error'">
            <slot name="error" :msg="error">
                <div class="color-danger" v-html="error" />
            </slot>
        </template>
        <template v-if="state === 'success'">
            <slot name="success">
                <div class="color-success">{{ strings.submit_success }}</div>
            </slot>
        </template>
    </div>
</template>

<script>
import http from '../../services/http';

export default {
    name: 'Form',
    props: {
        buttonClass: String,
        data: Object,
        formname: String,
        translations: {
            type: Object,
            default: () => ({}),
        },
        to: {
            type: Array,
        },
    },

    data() {
        return {
            loading: false,
            error: null,
            state: 'default',
            defaultTranslations: {
                submit: 'Verzenden',
                submit_success: 'Succesvol verzonden',
                submit_error: 'Er ging iets mis met verzenden',
            },
            strings: {},
        };
    },

    methods: {
        setTranslations() {
            this.strings = { ...this.defaultTranslations, ...this.translations };
        },

        onSubmit() {
            this.$emit('submit');

            if (this.formname === 'Userform') {
                this.patchUser();
            } else {
                const forminput = Object.entries(this.data).reduce((acc, [key, value],) => {
                    acc += `<strong>${key}</strong><br />${value}<br /><br />`;
                    return acc;
                }, '');

                const data = {
                    subject: this.formname,
                    to: this.to,
                    template: {
                        path: 'message.mjml',
                        data: {
                            data: {
                                title: this.formname,
                                text: 'Het contactformulier op je website is ingevuld.<br /><br />',
                                textblock: forminput,
                            }
                        }
                    }
                }
                if (this.$store.getters['Language/current']) {
                    data.lang = this.$store.getters['Language/current'];
                }

                const url = 'email/';
                this.loading = true;
                http.post(url, data).then(() => {
                    this.state = 'success';
                    this.$emit('success', { data: this.data });
                }).catch((err) => {
                    this.state = 'error';
                    this.$emit('error', { error: err, data: this.data });
                    this.error = `${this.strings.submit_error}: ${err && err.message ? err.message : ''}`;
                }).then(() => {
                    this.loading = false;
                });
            }
        },

        patchUser() {
            const { id, ...data } = { ...this.data };
            http.patch('items/clients/' + id, data).then(() => {
                this.state = 'success';
                this.$emit('success', { data: data });
            }).catch((err) => {
                this.state = 'error';
                this.$emit('error', { error: err, data: data });
                if (err?.response?.data?.code === 68) {
                    this.error = this.strings.password_too_short;
                } else {
                    this.error = `${this.strings.submit_error}: ${err && err.message ? err.message : ''}`;
                }
            }).then(() => {
                this.loading = false;
            });
        }
    },

    watch: {
        translations: {
            handler: 'setTranslations',
            immediate: true,
            deep: true,
        },
    },
};
</script>
